@import url("https://fonts.cdnfonts.com/css/gotham-6");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import url("https://db.onlinewebfonts.com/c/a64ff11d2c24584c767f6257e880dc65?family=Helvetica+Regular");

:root {
  // --primary: #102800;
  --primary: #122d01;
  --secondary: #becfa5;
  --blue: #1e90ff;
  --white: #ffffff;
  --star: #ffa108;
  --heroText: #72a344;
  --medium: #102800;
  --expoOut: cubic-bezier(0.16, 1, 0.3, 1);
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  background-color: var(--primary);
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0px !important;
}

.transition-all {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.section-primary {
  background-color: var(--primary);
}

.section-header-title {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  // margin-bottom: 20px;
  font-size: 65px;
  line-height: 80px;
  span {
    display: inline-block;
  }
}

.textColorPrimary {
  color: var(--primary);
}

.textColorSecondary {
  color: var(--white);
}

.primaryBg {
  background-color: var(--primary);
}

.secondaryBg {
  background-color: var(--secondary);
}

@media only screen and (max-width: 991px) {
  .section-header-title {
    margin-top: 80px;
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-header-title {
    font-size: 48px;
    line-height: 58px;
  }
}

@media only screen and (max-width: 767px) {
  .section-header-title {
    font-size: 38px;
    line-height: 48px;
  }
}

.list_point {
  margin-bottom: 30px;

  li {
    margin-bottom: 10px;
    font-family: "Helvetica Regular";
    font-size: 16px;
  }
}

.react-link {
  text-decoration: none;
}

/*---------------------------------------
  Hero section CSS
-----------------------------------------*/
.hero-section {
  padding: 60px 0px;
  height: 100vh;
  width: 100%;
  background-color: var(--primary);

  .hero-text {
    font-family: "Lato", sans-serif;
    font-size: 66px;
    line-height: 80px;
    font-weight: 300;
    text-transform: uppercase;
    color: var(--heroText);
    span {
      display: inline-block;
    }
  }
}

@media only screen and (max-width: 992px) {
  .hero-section {
    .hero-text {
      font-size: 40px;
      line-height: 50px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .hero-section {
    height: 100%;
    .hero-text {
      font-size: 40px;
      line-height: 50px;
      text-align: center;
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .hero-section {
    .hero-text {
      font-size: 40px;
      line-height: 50px;
    }
  }
}

/*---------------------------------------
  About section CSS
-----------------------------------------*/

.about-section {
  // padding: 60px;
  background-color: var(--secondary);
  height: 100vh;
  p {
    padding-bottom: 10px;
    font-family: "Helvetica Regular";
    color: var(--primary);
    font-size: 20px;
    line-height: 26px;
    text-align: justify;
    padding-right: 80px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .about-images-wrapper {
    position: relative;
    display: inline-block;
    .about-img-1 {
      max-width: 100%;
    }
    .about-img-2 {
      position: absolute;
      z-index: 2;
      bottom: -50px;
      left: -50px;
      width: 100%;
      max-width: 180px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .about-section {
    height: 100%;
    padding: 100px 0px 200px;

    p {
      padding-right: 0px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .about-section {
    p {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

/*---------------------------------------
  Surma section CSS
-----------------------------------------*/
.surma-section {
  height: 100vh;
}

@media only screen and (max-width: 767px) {
  .surma-section {
    padding: 60px 0px;
    height: 100%;
  }
}

/*---------------------------------------
  Certificate section CSS
-----------------------------------------*/
.reviews-section {
  padding: 60px 0px 60px;
  height: 100vh;
  background-color: var(--secondary);

  .reviews-section-wrap {
    .star-section {
      padding-right: 10px;
      .icon {
        color: var(--star);
        font-size: 30px;
        margin: 0px 4px;
      }
    }

    .text-section {
      padding-left: 10px;
      p {
        font-family: "Lato", sans-serif;
        font-weight: 500;
        font-size: 30px;
        line-height: 34px;
        color: var(--primary);
      }
    }
  }

  .reviews-wrapper {
    position: relative;
    border: 1px solid var(--primary);
    padding: 20px 30px 30px;
    border-radius: 25px;
    margin-top: 30px;
    min-height: 104px;

    .quote-wrapper {
      width: 35px;
      height: 60px;
      background-color: var(--secondary);
      position: absolute;
      top: -15px;
      left: -10px;
      text-align: center;
      img {
        width: 20px;
        height: auto;
      }
    }

    .reviews-box {
      background-color: var(--primary);
      width: 150px;
      height: 30px;
      border-radius: 0px 18px 18px 4px;
      position: absolute;
      top: -15px;
      right: 30px;
      .star-section {
        .icon {
          color: var(--star);
          font-size: 18px;
          margin: 0px 4px;
        }
      }
      &::before {
        content: "";
        position: absolute;
        // width: 30px;
        // height: 30px;
        right: 149px;
        width: 0;
        height: 0;
        border-top: 30px solid transparent;
        border-bottom: 0px solid transparent;
        border-right: 36px solid var(--primary);
      }
    }

    .reviews-box-bot {
      background-color: var(--primary);
      height: 30px;
      border-radius: 18px 0px 4px 18px;
      position: absolute;
      bottom: -15px;
      left: 30px;
      width: 150px;
      &::after {
        content: "";
        position: absolute;
        // width: 30px;
        // height: 30px;
        left: 149px;
        width: 0;
        height: 0;
        border-top: 0px solid transparent;
        border-bottom: 30px solid transparent;
        border-left: 36px solid var(--primary);
      }
    }

    p {
      font-family: "Helvetica Regular";
      color: var(--primary);
      font-size: 20px;
      line-height: 26px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .reviews-section {
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .reviews-section {
    .reviews-section-wrap {
      .star-section {
        .icon {
          font-size: 24px;
        }
      }
      .text-section {
        p {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    .reviews-wrapper {
      margin-top: 60px;
      .reviews-box {
        .star-section {
          .icon {
            color: var(--star);
            font-size: 16px;
          }
        }
      }

      p {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

/*---------------------------------------
  Certificate section CSS
-----------------------------------------*/
.certificate-section {
  padding: 60px 0px;
  background-color: var(--primary);
  .border-left {
    border-left: 1px solid var(--white);
  }

  .certificate-section-left {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    span {
      display: inline-block;
    }

    .certificate-icon {
      padding: 10px 20px;
      img {
        width: 100px;
        height: auto;
      }
    }
  }

  .certificate-section-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    text-align: center;

    .certificate-icon-text {
      padding: 10px 20px;
      img {
        width: 100px;
        height: auto;
      }

      .certificate-text {
        font-family: "Helvetica Regular";
        font-size: 20px;
        line-height: 24px;
        color: var(--secondary);
        padding-bottom: 0px;
        padding-top: 10px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .certificate-section {
    .border-left {
      border-left: none;
    }
  }
}

/*---------------------------------------
 Footer section CSS
-----------------------------------------*/

.footer-section {
  background-color: var(--secondary);
  padding: 80px 0px;

  .btn-wrapper {
    padding-top: 20px;
    .btn-container {
      height: 80px;
      position: relative;
      width: 80px;
      display: inline-flex;
      align-items: center;
      background-color: #548000;
      text-align: center;
      justify-content: center;
      border-radius: 50%;

      &:hover {
        cursor: pointer;
      }

      &::after {
        content: "";
        position: absolute;
        height: 70px;
        width: 70px;
        border-radius: 50%;
        z-index: 2;
        border: 1px solid #ffffff;
      }

      img {
        width: 40px;
      }
    }
    p {
      text-transform: uppercase;
      padding-top: 18px;
      font-size: 20px;
      color: var(--primary);
    }
  }

  .contact-wrapper {
    padding-bottom: 30px;
    &:last-child {
      padding-bottom: 0px;
    }

    .contact-title {
      display: block;
      color: var(--primary);
      padding-bottom: 5px;
      font-size: 20px;
      line-height: 26px;
      font-family: "Lato", sans-serif;
      font-weight: 300;
    }

    .contact-text {
      display: block;
      a {
        text-decoration: none;
        color: var(--primary);
        font-size: 20px;
        line-height: 26px;
        font-family: "Lato", sans-serif;
        font-weight: 300;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }

    .address-text {
      color: var(--primary);
      font-size: 18px;
      line-height: 24;
      display: block;
      padding-top: 5px;
      line-height: 24px;
      font-family: "Lato", sans-serif;
      font-weight: 300;
    }
  }

  .border-left {
    border-left: 2px solid var(--primary);
    padding-bottom: 10px;
    padding-left: 30px;
  }

  .button-wrap {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: auto;
    border: 0;
    box-shadow: none;
    height: 53.75px;
    line-height: 53.75px;
    padding: 0 31px 0 69px;
    cursor: pointer;
    position: relative;

    &:hover {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: auto;
      border: 0;
      box-shadow: none;
      height: 53.75px;
      line-height: 53.75px;
      padding: 0 2vw 0 4.5vw;
      cursor: pointer;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.0001;
      -webkit-appearance: none;
      appearance: none;
      cursor: pointer;
    }

    .text {
      position: relative;
      color: var(--primary);
      font-weight: 500;
      font-size: 18px;
      transform: translateX(0);
      transition: 0.7s cubic-bezier(0.16, 1, 0.3, 1);
      transition: all 0.7s var(--expoOut);
      will-change: transform;
      font-family: "Helvetica Regular";
    }

    .dot {
      position: absolute;
      overflow: hidden;
      background-color: transparent;
      left: 8px;
      top: 50%;
      width: 38.34px;
      height: 38.34px;
      transform: translateY(-50%) translateX(0) scale(1);
      box-shadow: 0 0 0 1px #f6efe2;
      box-shadow: 0 0 0 1px var(--secondary);
      transition: 0.7s cubic-bezier(0.16, 1, 0.3, 1);
      transition: all 0.7s var(--expoOut);
      display: inline-block;
      border-radius: 100%;
      will-change: transform;
    }

    &::before {
      content: "";
      display: block;
      width: 53.75px;
      height: 53.75px;
      border-radius: 53.75px;
      left: 0;
      top: 0;
      bottom: 0;
      transform: scale(1);
      transition: 0.7s cubic-bezier(0.16, 1, 0.3, 1);
      transition: all 0.7s var(--expoOut);
      will-change: transform;
      position: absolute;
      background-color: var(--primary);
      right: 0;
    }
  }

  .button-wrap:hover:before {
    width: 100%;
    transform: scale(0.9);
    transition: all 0.9s var(--expoOut);
  }

  .button-wrap:hover .dot {
    background-color: #f6efe2;
    background-color: var(--secondary);
    transform: translateY(-50%) translateX(0.5vw) scale(0.3225);
    transition: 0.9s cubic-bezier(0.16, 1, 0.3, 1);
    transition: all 0.9s var(--expoOut);
  }

  .button-wrap:hover .text {
    color: var(--secondary);
  }

  .dot:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    transform: translateX(0);
    background-image: url("./assets/arrow-right-white.svg");
    background-size: 50% auto;
    transition: all 0.7s var(--expoOut);
    will-change: transform;
    background-repeat: no-repeat;
    margin: 12px 0px 0px 8px;
  }

  .button-wrap:hover .dot:before {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-section {
    .btn-wrapper {
      .btn-container {
        height: 60px;
        width: 60px;

        &::after {
          height: 52px;
          width: 52px;
        }

        img {
          width: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .footer-section {
    padding: 60px 0px;
    .btn-wrapper {
      .btn-container {
        height: 60px;
        width: 60px;

        &::after {
          height: 52px;
          width: 52px;
        }

        img {
          width: 30px;
        }
      }
    }

    .border-left {
      border-left: none;
      padding-bottom: 0px;
      padding-left: 0px;
    }
    .button-wrap:hover {
      padding: 0 31px 0 69px;
    }
  }
}

/*---------------------------------------
 Header section CSS
-----------------------------------------*/
.header-section {
  padding: 10px 10px;
  background-color: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;

  .inner-wrapper {
    padding: 12px 20px;
    transition: all 0.2s linear;
    position: relative;
  }

  .inner-wrapper-bg {
    background-color: rgba($color: #000000, $alpha: 0.8);
    border-radius: 5px;
    @extend .transition-all;

    .logo-section {
      .logo {
        width: 100px;
        height: auto;
      }
    }

    .burger-section {
      .burger-icon {
        width: 26px;
      }
    }
  }

  .logo-section {
    .logo {
      width: 160px;
      height: auto;
      @extend .transition-all;
    }
  }

  .burger-section {
    .burger-icon {
      width: 40px;
      @extend .transition-all;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .dropdown-menu-wrapper {
    position: absolute;
    right: 0;
    bottom: 0px;
    margin-bottom: -160px;
    background-color: var(--secondary);
    padding: 10px 18px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding-bottom: 10px;
        text-align: right;
        &:last-child {
          padding-bottom: 0px;
        }

        .menu-link {
          text-decoration: none;
          font-family: "Helvetica Regular";
          color: var(--primary);
          font-size: 20px;
          line-height: 26px;
          font-weight: 500;
        }
      }
    }
  }

  .dropdown-menu-wrapper.active {
    opacity: 0.8;
    visibility: visible;
    transform: translateY(0);
  }
}

/*---------------------------------------
 Product range section CSS
-----------------------------------------*/
.product-range {
  height: 100vh;

  .productSliderWrapper {
    .slick-prev {
      left: 0;
      z-index: 99;
    }
    .slick-next {
      right: 0;
      z-index: 99;
    }
  }

  // .prev_content {
  //   .product-img {
  //     transform: rotate(-14deg);
  //   }
  // }
  // .center_content {
  //   .product-range-title {
  //     background-color: var(--secondary);
  //     color: var(--primary);
  //   }
  // }
  // .next_content {
  //   .product-img {
  //     transform: rotate(14deg);
  //   }
  // }

  .product-img {
    margin: 0 auto;
    max-height: 360px;
    // @extend .transition-all;
    -webkit-transition: all 250ms ease-in;
    -moz-transition: all 250ms ease-in;
    -ms-transition: all 250ms ease-in;
    -o-transition: all 250ms ease-in;
    transition: all 250ms ease-in;
  }

  .product-range-title {
    font-family: "Lato", sans-serif;
    font-size: 25px;
    line-height: 36px;
    color: var(--secondary);
    font-weight: 400;
    padding: 10px;
    @extend .transition-all;
    -webkit-transition: all 250ms ease-in;
    -moz-transition: all 250ms ease-in;
    -ms-transition: all 250ms ease-in;
    -o-transition: all 250ms ease-in;
    transition: all 250ms ease-in;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .product-range {
    .product-range-title {
      font-size: 24px;
      line-height: 30px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .product-range {
    .product-range-title {
      font-size: 18px;
      line-height: 24px;
    }
    .slick-center {
      .product-range-title {
        background-color: var(--secondary);
        color: var(--primary);
      }
    }
  }
}

/*---------------------------------------
  Product details section CSS
-----------------------------------------*/
.product-details {
  min-height: 100vh;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 30px;
  background-color: var(--secondary);
  .section-title {
    color: var(--primary);
    font-family: "Lato", sans-serif;
    font-size: 38px;
    line-height: 46px;
    text-transform: uppercase;
    padding-bottom: 20px;
    font-weight: 300;
    // padding-top: 100px;
  }

  .description-text {
    font-family: "Helvetica Regular";
    font-size: 20px;
    line-height: 26px;
    color: var(--primary);
  }

  .product-wrapper {
    .product-img {
      max-width: 220px;
      margin: 0 auto;
    }

    .product-details-text {
      display: block;
      color: var(--primary);
      font-family: "Helvetica Regular";
      font-style: italic;
      padding-top: 20px;
      margin-top: 30px;
      font-size: 18px;
      line-height: 24px;
    }

    .product-title {
      color: var(--primary);
      font-family: "Helvetica Regular";
      text-transform: uppercase;
      font-size: 20px;
      line-height: 26px;
      padding-left: 30px;
    }

    .product-certificate-section {
      display: flex;
      flex-wrap: wrap;
      text-align: center;

      .certificate-icon {
        padding: 10px 10px;
        img {
          width: 80px;
          height: auto;
        }
      }

      .certificate-icon-text {
        padding: 10px 10px;
        img {
          width: 80px;
          height: auto;
        }

        .certificate-text {
          font-family: "Helvetica Regular";
          padding-bottom: 0px;
          padding-top: 10px;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}

.slick-slide {
  padding: 0 15px;
}

@media only screen and (max-width: 1024px) {
  .product-details {
    height: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .product-details {
    .slick-slider {
      .slick-arrow.slick-prev {
        margin-left: 30px;
      }
      .slick-arrow.slick-next {
        margin-right: 30px;
      }
    }
  }
}

@media only screen and (max-width: 470px) {
  .product-details {
    .product-wrapper {
      .product-certificate-section {
        .certificate-icon-text,
        .certificate-icon {
          width: 50%;
        }
      }
    }
  }
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  transform-origin: top;
}

/*---------------------------------------
  Contact section CSS
-----------------------------------------*/

.contact-section {
  padding: 120px 0px 60px;
  height: 100%;
  width: 100%;
  background-color: var(--primary);

  .form-control {
    &:focus {
      box-shadow: none;
      border-color: navajowhite;
    }

    &::placeholder {
      font-family: "Helvetica Regular";
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      color: var(--primary);
    }
  }

  input[type="text"],
  input[type="email"] {
    padding: 0 28px;
    border-radius: 25px;
    box-shadow: none;
    height: 50px;
    font-family: "Helvetica Regular";
    font-size: 16px;
    font-weight: 500;
    color: var(--primary);
  }

  textarea {
    padding: 23px;
    border-radius: 25px;
    font-family: "Helvetica Regular";
    font-size: 16px;
    font-weight: 500;
    color: var(--primary);
  }

  .button-wrap {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: auto;
    border: 0;
    box-shadow: none;
    height: 53.75px;
    line-height: 53.75px;
    padding: 0 31px 0 69px;
    cursor: pointer;
    position: relative;

    &:hover {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: auto;
      border: 0;
      box-shadow: none;
      height: 53.75px;
      line-height: 53.75px;
      padding: 0 2vw 0 4.5vw;
      cursor: pointer;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.0001;
      -webkit-appearance: none;
      appearance: none;
      cursor: pointer;
    }

    .text {
      position: relative;
      color: #becfa5;
      color: #becfa5;
      font-weight: 500;
      font-size: 18px;
      transform: translateX(0);
      transition: 0.7s cubic-bezier(0.16, 1, 0.3, 1);
      transition: all 0.7s var(--expoOut);
      will-change: transform;
      font-family: "Helvetica Regular";
      line-height: 54px;
    }

    .dot {
      position: absolute;
      overflow: hidden;
      background-color: transparent;
      left: 8px;
      top: 50%;
      width: 38.34px;
      height: 38.34px;
      transform: translateY(-50%) translateX(0) scale(1);
      box-shadow: 0 0 0 1px #f6efe2;
      box-shadow: 0 0 0 1px var(--medium);
      transition: 0.7s cubic-bezier(0.16, 1, 0.3, 1);
      transition: all 0.7s var(--expoOut);
      display: inline-block;
      border-radius: 100%;
      will-change: transform;
    }

    &::before {
      content: "";
      display: block;
      width: 53.75px;
      height: 53.75px;
      border-radius: 53.75px;
      left: 0;
      top: 0;
      bottom: 0;
      transform: scale(1);
      background-color: var(--secondary);
      transition: 0.7s cubic-bezier(0.16, 1, 0.3, 1);
      transition: all 0.7s var(--expoOut);
      will-change: transform;
      position: absolute;
      background-color: #becfa5;
      right: 0;
    }
  }

  .button-wrap:hover:before {
    width: 100%;
    transform: scale(0.9);
    transition: all 0.9s var(--expoOut);
  }

  .button-wrap:hover .dot {
    background-color: #f6efe2;
    background-color: var(--medium);
    transform: translateY(-50%) translateX(0.5vw) scale(0.3225);
    transition: 0.9s cubic-bezier(0.16, 1, 0.3, 1);
    transition: all 0.9s var(--expoOut);
  }

  .button-wrap:hover .text {
    color: var(--primary);
  }

  .dot:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    transform: translateX(0);
    background-image: url("./assets/arrow-right-green.svg");
    background-size: 50% auto;
    transition: all 0.7s var(--expoOut);
    will-change: transform;
    background-repeat: no-repeat;
    margin: 12px 0px 0px 8px;
  }

  .button-wrap:hover .dot:before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .contact-section {
    padding: 130px 0px 60px;
    height: 100%;
    width: 100%;
    background-color: var(--primary);
  }
  .contact-section {
    .button-wrap:hover {
      padding: 0 31px 0 69px;
    }
  }
}

.overlayPageTransition {
  position: fixed;
  background-color: #000000;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  pointer-events: none;
  z-index: 999;
}

// .overlayLoaderWrapper {
//   position: fixed;
//   background-color: #ffffff;
//   top: 0px;
//   bottom: 0px;
//   left: 0px;
//   right: 0px;
//   z-index: 99;
//   pointer-events: none;
//   .overlayLoader {
//     position: fixed;
//     background-color: #000000;
//     top: 10px;
//     bottom: 10px;
//     left: 10px;
//     right: 10px;
//     border-radius: 15px;
//     pointer-events: none;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
//   .overlayImg {
//     z-index: 99;
//     img {
//       width: 250px;
//     }
//   }
// }

.go-back {
  color: var(--secondary);
  background-color: var(--primary);
  padding: 8px 16px;
  border-radius: 30px;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  font-weight: 400;
  @extend .transition-all;
}

.enquiryErrorMessage {
  display: block;
  margin-bottom: 15px !important;
  color: var(--white);
  font-size: 18px;
}
.errorMessage {
  color: red;
}

.pagenotfoundWrapper {
  background-color: var(--primary);
  color: var(--white);
  padding: 150px 0 100px;
  .head-blank-page {
    font-size: 70px;
  }
}

.detailNavArrows {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: calc(50vh - 20px);
  padding: 0 20px;
  left: 0;
  z-index: 9;
  .arrows {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: var(--primary);
    border-radius: 50%;
    position: relative;
    border: 1px solid var(--primary);
    cursor: pointer;
  }
  .arrows.deactivated {
    background-color: #949494;
    border: 1px solid #949494;
    cursor: default;
  }
  .prevArrow {
    position: absolute;
    top: 9px;
    left: 9px;
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 15px solid var(--secondary);
    border-bottom: 10px solid transparent;
  }
  .nextArrow {
    position: absolute;
    top: 9px;
    left: 14px;
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 15px solid var(--secondary);
    border-bottom: 10px solid transparent;
  }
}
